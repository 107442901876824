import { Controller } from '@hotwired/stimulus';
import { post, put } from '@rails/request.js';

// Connects to data-controller="finetuning-feedback"
export default class extends Controller {
  static targets = [
    'form',
    'ratingRadioButton',
    'ratingStar',
    'ratingDescription',
    'negativeCopy',
    'submitButton',
  ];

  connect() {
    this.handleStyleRatings();
    this.toggleNegativeCopy(this.selectedRadioButton()?.value);
  }

  selectedRadioButton = () => this.ratingRadioButtonTargets.find((radio) => radio.checked);

  // When mouse enter a star, update the description, and style stars
  handleMouseenterRating(event) {
    const labelFor = event.currentTarget.getAttribute('for');
    const ratingRadioButton = document.getElementById(labelFor);
    const ratingCopy = ratingRadioButton.dataset.ratingCopy;
    const ratingValue = ratingRadioButton.value;
    this.ratingDescriptionTarget.innerHTML = ratingCopy;

    this.styleRatingLabels(ratingValue);
  }

  // When mouse leave a star, reset description and style stars
  handleStyleRatings() {
    const selectedRadioButton = this.selectedRadioButton();
    if (selectedRadioButton) {
      // If selected, then set copy to ratingCopy of selected
      this.ratingDescriptionTarget.innerHTML = selectedRadioButton.dataset.ratingCopy;
      this.styleRatingLabels(selectedRadioButton.value);
    } else {
      // If none selected, then reset to "Select a star rating above"
      this.ratingDescriptionTarget.innerHTML = this.ratingDescriptionTarget.dataset.unselectedCopy;
      this.styleRatingLabels(0);
    }
  }

  // When rating is selected, update the form (w/ negative copy or not) and submit rating
  handleRatingSelected(event) {
    this.toggleNegativeCopy(event.target.value);
    this.submitRating(event.target.value);
  }

  toggleNegativeCopy(rating) {
    if (parseInt(rating) < 3) {
      this.negativeCopyTarget.classList.remove('hidden');
    } else {
      this.negativeCopyTarget.classList.add('hidden');
    }
  }

  submitRating(rating) {
    this.submitForm(JSON.stringify({ finetuning_feedback: { rating: rating } }));
  }

  styleRatingLabels(value) {
    this.ratingStarTargets.forEach((star, idx) => {
      if (idx <= value - 1) {
        star.classList.add('text-yellow-500');
      } else {
        star.classList.remove('text-yellow-500');
      }
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(this.formTarget);
    if (!data.get('finetuning_feedback[rating]')) {
      alert('Please select a star rating.');
    } else if (!data.get('finetuning_feedback[comment]')) {
      alert('Please add a comment.');
    } else {
      this.submitButtonTarget.disabled = true;
      this.submitForm(data);
    }
  }

  submitForm(body) {
    // For the initial rating, we'll need to post to create the feedback record.
    // Subsequent ratings will be changes/updates to the persisted feedback record via put.
    const method = this.formTarget.elements._method.value == 'post' ? post : put;
    method(this.formTarget.action, { body: body, responseKind: 'json' }).then((req) => {
      req.response.json().then((data) => {
        if (data.error_message) {
          alert(`Something went wrong - ${data.error_message}.`);
        } else if (data.form_action) {
          this.formTarget.action = data.form_action;
          this.formTarget.elements._method.value = 'put';
        } else {
          // Replace history state to prevent reopening modal on redirect back
          Turbo.visit(window.originalUrl, { action: 'replace' });
        }
      });
    });
  }
}
